import '@/styles/globals.css';
import App, { AppContext, AppProps } from 'next/app';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Head from 'next/head';
import { getSdk, SiteSettings } from '@/services/graphql';
import { graphqlClient } from '@/services/graphql-client';
import { SiteSettingsContext } from '@/providers/siteSettingsProvider';
import 'keen-slider/keen-slider.min.css';
import '@/fonts/icomoon/style.css';
import 'yet-another-react-lightbox/styles.css';

const theme = {
  sizes: {
    container: {
      xl: '1440px',
    },
  },
  colors: {
    primary: '#ca4121',
    'primary-800': '#ca4121aa',
    'primary-100': '#ca412111',
    'primary-200': '#ca412133',
    MenuText: '#fff',
    'gray-800': '#212529',
  },
  fonts: {
    heading: 'Merriweather',
    body: 'Open Sans',
    menubar: 'Merriweather',
  },
};

const themeExtended = extendTheme(theme);

const MyApp = ({ Component, pageProps }: AppProps<{ siteSettings: SiteSettings }>) => (
  <SiteSettingsContext.Provider value={pageProps.siteSettings}>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{pageProps.siteSettings.title}</title>
      <meta name="description" content={pageProps.siteSettings.description || ''} />
    </Head>
    <ChakraProvider theme={themeExtended}>
      <Component {...pageProps} />
    </ChakraProvider>
  </SiteSettingsContext.Provider>
);

MyApp.getInitialProps = async (appContext: AppContext) => {
  const sdk = getSdk(graphqlClient);
  const { data } = await sdk.getSiteSettigns();

  await App.getInitialProps(appContext);

  return {
    pageProps: {
      siteSettings: data.SiteSettings,
    },
  };
};

export default MyApp;
